const TradingTranslations = {
  'en-US': {
    trading: {
      pageTitle: 'Copy Trading',
      hero: {
        title1: 'COPY TRADING',
        title2: 'PLATFORM',
        desc:
          'COPY TRADING PLATFORM, is a product we developed for our client, which is designed to enable users to ' +
          'easily replicate the trading strategies of top-performing traders, allowing them to achieve similar success ' +
          'in the financial markets. With our advanced copy trading platform, users can access real-time trading data,' +
          ' make informed investment decisions, and benefit from the expertise of leading traders in the industry. ' +
          'Whether you are a seasoned investor or a beginner in the world of finance, our copy trading platform offers' +
          ' a simple and effective way to access the knowledge and skills of top traders and achieve your financial goals.',
      },
      features: {
        tradingStrategy: {
          title: 'Fully Trading Automation',
          desc: 'We are a quantitative trading firm. We employ a fully automated approach, extracting signals with mathematical models based on over 20 years of historical data. We sell, use, and maintain high quality market TB data, which we use to make predictions. We time each trade down to the order of nanoseconds by probability, in order to achieve auto profit targets and ensure auto stop loss.',
        },
        riskManagement: {
          title: 'Risk Management',
          desc: 'All of our trading strategies must fulfill less than 10% drawn historically. To split risk, we also have numerous working and proven automated trading strategies running live. Unlike most investment funds that often lose a lot in stock market crashes, we not only have long strategies, but also parallel short strategies, so that both market can be profitable.',
        },
        portfolio: {
          title: 'Portfolio Dashboard',
          desc: 'A detailed reporting dashboard of the net profit, gross profit, cash, liquid, maximum drawdown, profit factor, and other metrics. We never risk our fund by more than 15%, or an alert will be sent to our client for unconditional exit.',
        },
        indexFuture: {
          title: 'Index Future Trading',
          desc: 'US Index futures are traded in CME.  This is what our firm has focused and has automatically traded on for over than 20 years. AI TRADE has also developed a trading strategy platform which is used by numerous companies.',
        },
        cryptoTrading: {
          title: 'Cryptocurrency Trading',
          desc: 'Cryptocurrency trading is a growing market.  We are currently in the development phase, and expect to launch a mature model and system around 2023, at which time we will offer our clients cryptocurrency investment options.',
        },
        fundReq: {
          title: 'Profit Report',
          desc: 'With our smooth and streamlined billing system, you can easily manage your invoices and payments, reducing the risk of errors and ensuring that your financial transactions are handled quickly and efficiently',
        },
      },
    },
  },
}

export default TradingTranslations
