const MediaTranslations = {
  'en-US': {
    media: {
      pageTitle: 'HISTORICAL DATA',
      hero: {
        title1: 'HISTORICAL DATA',
        title2: 'PROVIDER',
        desc:
          'HISTORICAL DATA PROVIDER, is a product we developed for our client. As a leading tick data provider, we offer' +
          ' high-quality, granular market data that enables traders and investors to make informed decisions and ' +
          'maximize returns. Our tick data provider offers a comprehensive range of historical and real-time market' +
          ' data feeds, providing users with unparalleled insights into market trends and behaviors.',
      },
      features: {
        profitReturn: {
          title: 'AI Content Creation',
          desc: 'Massive machine creates content creation often, this does automatic posting and content writing. Our AI technology shows promise in creating audio and video, too.',
        },
        portfolio: {
          title: 'Trending Alerts',
          desc: 'Our trending alerts developed help tracking the latest trends associated with business to gain product or service ideas which weight our AI content generation over time.',
        },
        infrastructure: {
          title: 'Big Data Marketing',
          desc: 'Our marketing, big data comprises gathering, analyzing, and using massive amounts of digital information to improve business operations.',
        },
        fundReq: {
          title: 'Profit Report',
          desc: 'With our smooth and streamlined billing system, you can easily manage your invoices and payments, reducing the risk of errors and ensuring that your financial transactions are handled quickly and efficiently',
        },
      },
    },
  },
}

export default MediaTranslations
