import getFullYear from '../../../../Helper/Date/DateFormatter'

const layoutTranslations = {
  'en-US': {
    header: {
      home: 'HOME',
      products: 'WORK',
      trading: 'COPY TRADING',
      media: 'HISTORICAL DATA',
      mining: 'CRYPTO PORTAL',
      technology: 'SERVICES',
      about: 'ABOUT',
      contact: 'CONTACT',
    },
    footer: {
      copyright: `Copyrights © AI TRADE, INC  2005-${getFullYear}`,
      policy: 'Privacy Policy',
      terms: 'Terms of Use',
    },
  },
}

export default layoutTranslations
