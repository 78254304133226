const MiningTranslations = {
  'en-US': {
    mining: {
      pageTitle: 'CRYPTO PORTAL',
      hero: {
        title1: 'CRYPTO PORTAL',
        title2: 'SYSTEM',
        desc:
          'CRYPTO PORTAL SYSTEM, is a product we developed for our client. Our crypto portal system provides a ' +
          'comprehensive platform for users to access and manage their crypto assets, as well as stay informed about' +
          ' the latest news and market trends. As a leading provider of crypto portal systems, we are committed ' +
          'to delivering innovative solutions that provide users with a simple and effective way to manage their ' +
          'crypto assets and stay informed about the latest developments in the crypto market.',
      },
      features: {
        profitReturn: {
          title: 'Fully Mining Automation',
          desc: 'An elite solution that provides full control of every device and a complete overview of your mining farms in one place to make insightful decisions. Mining farms can boost profits and decrease manual intervention with Cudo’s unique solution.',
        },
        riskManagement: {
          title: 'Risk Management',
          desc: 'We forecast the net income, having stream lined the entry and exit points for the cash, coins, and the machine purchasing and selling algorithms.',
        },
        portfolio: {
          title: 'Portfolio Dashboard',
          desc: 'A detailed, real time reporting dashboard of the hash power, cash, profit, and machine(s) for each client. Clients can easily monitor the risk and profit metrics, putting them in control.',
        },
        infrastructure: {
          title: 'Infrastructure',
          desc: 'Our blockchain infrastructure company that operates and joined the largest cryptocurrency mining operations in North America to have the largest hash power together.',
        },
        cryptoTrading: {
          title: 'Cryptocurrency Trading',
          desc: 'We use our hedge fund to perform automated quantitative trading on the rising crypto currencies and US index futures.',
        },
        fundReq: {
          title: 'Profit Report',
          desc: 'With our smooth and streamlined billing system, you can easily manage your invoices and payments, reducing the risk of errors and ensuring that your financial transactions are handled quickly and efficiently',
        },
      },
    },
  },
}

export default MiningTranslations
