const homepageTranslations = {
  'en-US': {
    homepage: {
      pageTitle: 'Home',
      hero: {
        title: 'AI TRADE, INC',
        subTitle: 'FINTECH CONSULTING & SERVICES',
        desc: 'At AI TRADE, we specialize in algorithmic trading development and technology consulting for the finance industry. Our team of experts combines industry knowledge and innovative technology to help you make smarter financial products.',
      },
      products: {
        title1: 'OUR',
        title2: 'SERVICES',
        wealth: {
          title: 'SOFTWARE DEVELOPMENT',
          desc: 'Our finance software development services are designed to optimize your workflows and improve your overall business performance. Our finance software development services are designed to optimize your workflows and improve your overall business performance.',
        },
        technology: {
          title: 'CONSULTING',
          desc: 'Drawing upon more than a decade of experience in developing reliable trading strategies, AI TRADE collaborates with a diverse range of trading firms and individual traders. Our seamless integration via a user-friendly strategy API and accessible online strategy services enables streamlined access to our cutting-edge technology.',
        },
      },
      about: {
        title1: 'ABOUT',
        title2: 'AI TRADE',
        section1: {
          title: '1993-2007',
          subTitle: 'Our Humble Beginnings',
          desc: 'After conducting a comprehensive analysis of the Hong Kong Exchange, AI TRADE leveraged spreadsheets to develop its inaugural trading algorithm and implemented a multi-exchange server software and databases.',
        },
        section2: {
          title: 'DECEMBER 2008',
          subTitle: 'The firm is Born',
          desc: 'Following the market crash of 2008, AI TRADE provided assistance to trading firms in developing their Long-Short Strategy, which has proven to be highly profitable in both bearish and bullish market conditions.',
        },
        section3: {
          title: 'JUNE 2010',
          subTitle: 'Transition to Global Service',
          desc: 'AI TRADE has made the strategic decision to transfer its global headquarters from Hong Kong to the United States.',
        },
        section4: {
          title: 'October 2018',
          subTitle: 'Capital Expansion',
          desc: 'AI TRADE has established a business presence in Orlando, Florida, and is focused on enhancing its financial services through the development of cutting-edge software technologies.',
        },
        endText: {
          title1: 'BE PART OF OUR',
          title2: 'STORY',
        },
      },
      button: {
        title: 'know more',
      },
    },
  },
}

export default homepageTranslations
