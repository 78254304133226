const aboutTranslations = {
  'en-US': {
    about: {
      pageTitle: 'About',
      hero: {
        title1: 'About',
        title2: 'AI TRADE',
        desc: 'As an AI trading software development firm, we are dedicated to providing cutting-edge solutions that enable traders to leverage the power of advanced machine learning and predictive analytics to make better and more informed trading decisions. Our software development firm provides a full suite of services, from initial ideation and requirements gathering to design, development, testing, and deployment, ensuring a seamless and efficient process from start to finish. Our AI trading software development firm specializes in creating highly sophisticated algorithms that can analyze vast amounts of data in real-time, providing traders with valuable insights and enabling them to stay ahead of the curve.',
        desc2:
          'With a focus on quality, scalability, and user experience, our software development firm has a proven track record of delivering successful projects across a variety of industries and use cases. Our team of experienced software developers, engineers, and designers are committed to staying ahead of the curve in the rapidly-evolving technology landscape, continuously improving our processes and technologies to deliver best-in-class solutions. Whether you are looking to build a new software product from scratch or modernize an existing system, our software development firm has the expertise and resources to help you achieve your goals and drive growth and innovation for your business.',
      },
    },
  },
}

export default aboutTranslations
