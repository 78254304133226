const contactTranslations = {
  'en-US': {
    contact: {
      pageTitle: 'Contact Us',
      title: {
        title1: 'CONTACT',
        title2: ' US',
      },
      contactDetail: 'You may send email to office@aitrade.ai or call us at: (347) 690-5545',
      address: {
        title: 'Mailing Address',
        address1: 'AI TRADE, INC',
        address2: '3505 Lake Lynda Drive',
        address3: 'Suite 200',
        address4: 'Orlando, Florida 32817',
      },
      form: {
        title: 'GET IN TOUCH',
        name: {
          label: 'FULL NAME *',
          placeholder: 'FULL NAME',
        },
        email: {
          label: 'EMAIL *',
          placeholder: 'MAIL@EXAMPLE.COM',
        },
        number: {
          label: 'PHONE NUMBER *',
          placeholder: '88888 88888',
        },
        topic: {
          label: 'SELECT SERVICE *',
          options: {
            technology: 'Technology Solution',
            other: 'Others',
          },
        },
        message: {
          label: 'MESSAGE *',
          placeholder: 'YOUR MESSAGE',
        },
        button: 'SEND MESSAGE',
      },
    },
  },
}

export default contactTranslations
