import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import aboutTranslations from './Resource/About'
import contactTranslations from './Resource/Contact'
import errorTranslations from './Resource/Error'
import homepageTranslations from './Resource/Homepage'
import layoutTranslations from './Resource/Layout'
import MediaTranslations from './Resource/Media'
import MiningTranslations from './Resource/Mining'
import ProductsTranslations from './Resource/Products'
import TechnologyTranslations from './Resource/Technology'
import TradingTranslations from './Resource/Trading'

const fallbackLanguage = 'en-US'
const lookupKey = 'pm-lang'

i18n
  .use(initReactI18next)
  .init({
    detection: {
      order: ['cookie', 'zhDetector', 'navigator'],
      caches: ['cookie'],
      lookupCookie: lookupKey,
      cookieDomain: process.env.REACT_APP_PORTAL_AITRADE_DOMAIN,
    },
    resources: {
      'en-US': {
        translation: {
          ...layoutTranslations['en-US'],
          ...TradingTranslations['en-US'],
          ...MiningTranslations['en-US'],
          ...MediaTranslations['en-US'],
          ...TechnologyTranslations['en-US'],
          ...homepageTranslations['en-US'],
          ...aboutTranslations['en-US'],
          ...contactTranslations['en-US'],
          ...errorTranslations['en-US'],
          ...ProductsTranslations['en-US'],
        },
      },
    },
    lng: i18n.options.lng,
    debug: false,
    fallbackLng: fallbackLanguage,
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(() => null)

export default i18n
