const TechnologyTranslations = {
  'en-US': {
    technology: {
      pageTitle: 'AI TRADE, INC.',
      hero: {
        title1: 'EDGE',
        title2: ' TECHNOLOGY',
        desc: 'Over the years, we have established a valuable partnership with the finance firms, leveraging our expertise in edge technologies to help build and automate their infrastructure with a suite of complex services including cloud hosting, application development, software licensing, and more.',
      },
      body: {
        infrastructure: {
          title: 'Technology Consulting',
          subTitle: 'Software and hardware',
          desc: 'We offer comprehensive cloud solutions and highly scalable information technology services, as well as software and hardware developments and licenses to provide our clients with a one-stop-shop for their technology needs.',
        },
        automation: {
          title: 'Software Automation',
          subTitle: 'Monitoring and Tasks',
          desc: 'Our software automation solutions provide a seamless and intuitive user experience, allowing even non-technical users to easily automate their software development and testing processes and improve their software quality. By implementing software automation, businesses can achieve greater consistency and reliability in their software development and testing, resulting in faster time-to-market and improved customer satisfaction.',
        },
      },
    },
  },
}

export default TechnologyTranslations
