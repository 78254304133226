import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { NavLink as Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import './Main.scss'
import uris from '../../../../Config/Router/URI'
import uriEncode from '../../../../Helper/URI/Encode'
import logo from '../Images/logo.png'

const NavLinks: React.FC<{
  mobile?: boolean
  setOpenMenu?: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ mobile, setOpenMenu }) => {
  const { t } = useTranslation()

  return (
    <nav className="nav-links">
      <ul className={`link-list ${mobile && 'mobile'}`}>
        <li>
          <Link
            onClick={() => setOpenMenu && setOpenMenu(false)}
            exact
            activeClassName="current-nav"
            to={uris.home}
          >
            {t('header.home')}
          </Link>
        </li>
        <li className="product-dropdown">
          <Link
            onClick={() => setOpenMenu && setOpenMenu(false)}
            exact
            activeClassName="current-nav"
            to={uris.products}
          >
            {t('header.products')}
            <FontAwesomeIcon style={{ marginLeft: '4px' }} icon={['fas', 'caret-down']} />
          </Link>
          <div className="sub-menu">
            <Link onClick={() => setOpenMenu && setOpenMenu(false)} exact to={uris.trading}>
              {t('header.trading')}
            </Link>
            <Link onClick={() => setOpenMenu && setOpenMenu(false)} exact to={uris.media}>
              {t('header.media')}
            </Link>
            <Link onClick={() => setOpenMenu && setOpenMenu(false)} exact to={uris.mining}>
              {t('header.mining')}
            </Link>
          </div>
        </li>
        <li className="technology-dropdown">
          <Link
            onClick={() => setOpenMenu && setOpenMenu(false)}
            exact
            activeClassName="current-nav"
            to={uris.technology}
          >
            {t('header.technology')}
            <FontAwesomeIcon style={{ marginLeft: '4px' }} icon={['fas', 'caret-down']} />
          </Link>
          <div className="sub-menu technology-sub-menu">
            <HashLink
              onClick={() => setOpenMenu && setOpenMenu(false)}
              to={`${uris.technology}#${uriEncode('infrastructure')}`}
            >
              {t('technology.body.infrastructure.title').toLocaleUpperCase()}
            </HashLink>
            <HashLink
              onClick={() => setOpenMenu && setOpenMenu(false)}
              to={`${uris.technology}#${uriEncode('automation')}`}
            >
              {t('technology.body.automation.title').toLocaleUpperCase()}
            </HashLink>
          </div>
        </li>
        <li>
          <Link
            onClick={() => setOpenMenu && setOpenMenu(false)}
            activeClassName="current-nav"
            to={uris.about}
          >
            {t('header.about')}
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setOpenMenu && setOpenMenu(false)}
            activeClassName="current-nav"
            to={uris.contact}
          >
            {t('header.contact')}
          </Link>
        </li>
      </ul>
    </nav>
  )
}

const LogoMain: React.FC<{ setOpenMenu?: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  setOpenMenu,
}) => {
  return (
    <Link onClick={() => setOpenMenu && setOpenMenu(false)} className="logo-link" to={uris.home}>
      <div className="logo-container">
        <img src={logo} alt="AI TRADE Logo" />
      </div>
    </Link>
  )
}

const HeaderMain: React.FC<{
  theme?: 'light' | 'dark' | 'dark-nav'
}> = ({ theme }) => {
  const [openMenu, setOpenMenu] = useState(false)
  return (
    <>
      <Helmet titleTemplate="%s | AI TRADE">AI TRADE</Helmet>
      <header className={`header-main ${theme}`}>
        <section>
          <LogoMain />
          <NavLinks />
        </section>
      </header>
      <header className={`header-main mobile ${theme}`}>
        <section>
          <div className="header-left">
            <Button className="menu-open-button" onClick={() => setOpenMenu(!openMenu)}>
              <FontAwesomeIcon icon={['fas', 'bars']} />
            </Button>
            <LogoMain />
          </div>
          <div className={openMenu ? 'nav-list-mobile open' : 'nav-list-mobile'}>
            <div className="nav-links-header">
              <LogoMain setOpenMenu={setOpenMenu} />
              <FontAwesomeIcon onClick={() => setOpenMenu(false)} icon={['fas', 'times']} />
            </div>
            <ul className="nav-links-container">
              <NavLinks setOpenMenu={setOpenMenu} mobile />
            </ul>
          </div>
          <button
            type="button"
            onClick={() => setOpenMenu(false)}
            className={`nav-list-background ${!openMenu && 'close'}`}
          />
        </section>
      </header>
    </>
  )
}

export default React.memo(HeaderMain)
