import './View/Layout/Styles/Globals/_style.scss'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './Config/Icons/FontAwesome/Main'
import './Config/Language/I18n/Initialize'

import React from 'react'

import ReactDOM from 'react-dom'

import App from './Config/App/App'

ReactDOM.render(<App />, document.getElementById('root'))
