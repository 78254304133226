// pls sort alphabetically by uri!
const uris = {
  about: '/about',
  contact: '/contact',
  home: '/',
  products: '/products',
  trading: '/trading',
  mining: '/mining',
  media: '/media',
  technology: '/technology',
  external: {},
}

export default uris
