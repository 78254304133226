const ProductsTranslations = {
  'en-US': {
    management: {
      pageTitle: 'AI TRADE, INC',
      hero: {
        title1: 'SOFTWARE',
        title2: 'DEVELOPMENT',
        desc:
          'At our fintech firm, we are dedicated to developing secure and user-friendly financial software that ' +
          'empower our clients to achieve their financial goals for their customers and businesses.',
      },
      trading: {
        title1: 'COPY TRADING',
        title2: 'PLATFORM',
        desc:
          'COPY TRADING PLATFORM, is a product we developed for our client, which is designed to enable users to ' +
          'easily replicate the trading strategies of top-performing traders, allowing them to achieve similar success ' +
          'in the financial markets. With our advanced copy trading platform, users can access real-time trading data,' +
          ' make informed investment decisions, and benefit from the expertise of leading traders in the industry. ' +
          'Whether you are a seasoned investor or a beginner in the world of finance, our copy trading platform offers' +
          ' a simple and effective way to access the knowledge and skills of top traders and achieve your financial goals.',
      },
      media: {
        title1: 'HISTORICAL DATA',
        title2: 'PROVIDER',
        desc:
          'HISTORICAL DATA PROVIDER, is a product we developed for our client. As a leading tick data provider, we offer' +
          ' high-quality, granular market data that enables traders and investors to make informed decisions and ' +
          'maximize returns. Our tick data provider offers a comprehensive range of historical and real-time market' +
          ' data feeds, providing users with unparalleled insights into market trends and behaviors.',
      },
      mining: {
        title1: 'CRYPTO PORTAL',
        title2: 'SYSTEM',
        desc:
          'CRYPTO PORTAL SYSTEM, is a product we developed for our client. Our crypto portal system provides a ' +
          'comprehensive platform for users to access and manage their crypto assets, as well as stay informed about' +
          ' the latest news and market trends. As a leading provider of crypto portal systems, we are committed ' +
          'to delivering innovative solutions that provide users with a simple and effective way to manage their ' +
          'crypto assets and stay informed about the latest developments in the crypto market.',
      },
    },
  },
}

export default ProductsTranslations
